import sendApiRequest from "@/axios/requestApi";
import { apiConfig } from "@/constant/apiConfig";
import { useEffect, useState } from "react";

import ImgBlack from "@/assets/images/membership/black.png";
import ImgGold from "@/assets/images/membership/gold.png";
import ImgSilver from "@/assets/images/membership/silver.png";
import ImgDiamond from "@/assets/images/membership/diamond.png";

import { formatPrice } from "@/utils/formatPrice";
import ModalBuySuccessNft from "./ModalBuySuccessNft";
import { message } from "antd";
import { useNavigate } from "react-router-dom";
import { UrlInternal } from "@/constant/endpointRoute";
import ImgArrowLeft from "@/assets/img/arrow-left.png";
import ModalBuyNowNft from "./ModalBuyNowNft";
import { useAppSelector } from "@/state/hooks";
import { isAuthSelect } from "@/state/authSlice";
import WhatIsMembershipNft from "./WhatIsMembershipNft";
import NftIssuance from "./NftIssuance";

const dataMembership = [
  {
    img: ImgBlack,
    content:
      "The Black Membership is the most prestigious level in the Membership NFT system, offering an additional 200% profit rate, maximizing earnings from products and services. ",
    type: "Black",
  },
  {
    img: ImgDiamond,
    content:
      "The Diamond Membership holds the second-highest tier, providing an additional 150% profit rate to significantly boost income. Members can access exclusive and special offers.",
    type: "Diamond",
  },
  {
    img: ImgGold,
    content:
      "The Gold Membership offers a balanced level of benefits, with an additional 120% profit rate, helping users enhance their revenue potential. They do not receive exclusive product access .",
    type: "Gold",
  },
  {
    img: ImgSilver,
    content:
      "The Silver Membership provides foundational benefits with an additional 110% profit rate. Holders do not have early access to new features or priority trading rights.",
    type: "Silver",
  },
];

function MembershipNft() {
  const [dataTable, setDataTable] = useState<any>([]);
  const [modalNft, setModalNft] = useState({
    isOpen: false,
    type: "",
  });
  const navigate = useNavigate();
  const [isBuyNft, setIsBuyNft] = useState({
    isOpen: false,
    data: null,
  });
  const isAuth = useAppSelector(isAuthSelect);
  const SEASON = "Season 1";

  const getListMembership = async () => {
    try {
      const resp: any = await sendApiRequest({
        ...apiConfig.membershipNft.getListProduct,
        body: {
          page: 1,
          size: 30,
          querySearch: "",
        },
      });
      if (resp.result) {
        setDataTable(resp.data.datas);
      }
    } catch (error) {
      setDataTable([]);
    }
  };

  useEffect(() => {
    getListMembership();
  }, []);

  const handleBuyNft = async (data: any) => {
    try {
      const resp: any = await sendApiRequest({
        ...apiConfig.membershipNft.buyNft,
        body: {
          membershipId: data?.id,
        },
      });
      if (resp.result) {
        setModalNft({
          isOpen: true,
          type: data?.type,
        });
      }
    } catch (error) {
      // message.error("Buy NFT failed");
    }
    setIsBuyNft({ isOpen: false, data: null });
  };

  const reversedData = [...dataTable].reverse();

  return (
    <>
      <ModalBuySuccessNft
        isVisible={modalNft.isOpen}
        setCancel={() => setModalNft({ isOpen: false, type: "" })}
        type={modalNft?.type}
      />
      <ModalBuyNowNft
        isVisible={isBuyNft.isOpen}
        setCancel={() => setIsBuyNft({ isOpen: false, data: null })}
        data={isBuyNft?.data}
        onSubmit={handleBuyNft}
      />
      <div className="membership-nft">
        <div className="max-w-[1608px] mx-auto sm:mt-[68px] md:mb-[93px] mb-20">
          <div className="flex items-center justify-between sm:mt-0 mt-[16.5px] mb-[33px]">
            <div
              className="min-w-[24px] mb-[10px]"
              // onClick={() => navigate(UrlInternal.HOME)}
              onClick={() => {
                navigate(UrlInternal.HOME, {
                  state: {
                    isBack: true,
                  },
                });
              }}
            >
              <img src={ImgArrowLeft} alt="img" className="sm:hidden" />
            </div>
            <div className="flex flex-col">
              <div className="text-[#B0F127] text-[15px] font-montserrat600 md:mb-3 text-center md:order-1 order-2">
                {SEASON}
              </div>
              <div className="sm:text-[32px] text-[20px] font-montserrat700 text-center leading-none md:order-2 order-1">
                Membership NFT
              </div>
            </div>
            <div className="min-w-[24px]"></div>
          </div>
          <div className="grid lg:grid-cols-[1fr_1fr_1fr_1fr] sm:grid-cols-[1fr_1fr] grid-cols-[1fr] gap-[24px] 2xl:px-0 px-[16px]">
            {reversedData?.map((dt: any, i: number) => (
              <div
                className="border border-solid border-[#FFFFFF33] rounded-[50px] overflow-hidden flex flex-col"
                key={i}
              >
                <img
                  src={dataMembership[i]?.img}
                  alt="img"
                  className="w-full"
                />

                <div className="2xl:p-[24px] pb-[32px] px-[16px] pt-[24px] flex flex-col flex-1">
                  <div className="flex-1">
                    <div className="text-[28px] font-montserrat700">
                      {dt?.productName}
                    </div>
                    <div className="text-[#C0C0C0] text-[14px] pt-[8px]">
                      {dataMembership[i]?.content}
                    </div>
                    <div className="text-[#C0C0C0] text-[14px] pb-[16px]">
                      The total supply of {dt?.productName} is{" "}
                      <span className="font-montserrat600 text-white">
                        {dt?.totalSupply}
                      </span>
                      .
                    </div>
                  </div>
                  <div className="mt-auto">
                    <div className="border-gradient-membership pb-[16px] mb-[16px]">
                      <div className="flex">
                        <div className="flex-1">
                          <div className="text-[#C8C8C8] text-[14px]">
                            Extra Profit
                          </div>
                          <div className="text-[18px] font-montserrat700 pt-[4px]">
                            {formatPrice(dt?.bonusStakingIncrease * 100 + 100)}%
                          </div>
                        </div>
                        <div className="flex-1">
                          <div className="text-[#C8C8C8] text-[14px]">
                            Staking Limit
                          </div>
                          <div className="text-[18px] font-montserrat700 pt-[4px]">
                            {formatPrice(dt?.limitStakingSametimeAmount)} USD
                          </div>
                        </div>
                      </div>
                      <div className="flex mt-4">
                        <div className="flex-1">
                          <div className="text-[#C8C8C8] text-[14px]">
                            {SEASON} Supply
                          </div>
                          <div className="text-[18px] font-montserrat700 pt-[4px]">
                            {formatPrice(dt?.totalSupplyThisSeason)}
                          </div>
                        </div>
                        <div className="flex-1">
                          <div className="text-[#C8C8C8] text-[14px]">
                            Remaining
                          </div>
                          <div className="text-[18px] font-montserrat700 pt-[4px]">
                            {formatPrice(
                              dt?.totalSupplyThisSeason -
                                dt?.totalSoldThisSeason
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="flex lg:items-start md:items-center items-start justify-between gap-[16px] md:flex-row flex-col">
                      <div>
                        <div className="text-[#C8C8C8] text-[14px] pb-[8px]">
                          Price
                        </div>
                        <div className="text-[#B0F127] text-[24px] font-montserrat700">
                          {formatPrice(dt?.sellPrice)} {dt?.sellAssetType}
                        </div>
                      </div>
                      <button
                        onClick={() => {
                          !isAuth
                            ? navigate(UrlInternal.LOGIN)
                            : setIsBuyNft({
                                isOpen: true,
                                data: {
                                  ...dt,
                                  type: dataMembership[i]?.type,
                                  image: dataMembership[i]?.img,
                                },
                              });
                        }}
                        className="bg-[#B0F127] rounded-[12px] font-montserrat700 text-[18px] text-[#060606] h-[60px] md:w-[130px] w-full border-none cursor-pointer"
                      >
                        Buy Now
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>

        <WhatIsMembershipNft />
        <NftIssuance dataProduct={reversedData} />
      </div>
    </>
  );
}

export default MembershipNft;
