import ImgNft from "@/assets/images/membership/nft.svg";

function WhatIsMembershipNft() {
  return (
    <div className="bg-[#141414] box-clipath">
      <div className="max-w-[1312px] mx-auto px-4 md:pt-20 pt-[65px] md:pb-40 pb-[88px] w-full">
        <div className="flex lg:items-center justify-between gap-[36px] lg:flex-row flex-col">
          <div>
            <div className="md:text-[54px] text-[32px] font-montserrat600 md:leading-[66px] leading-none">
              What Is a <div className="text-[#B0F127]">Membership NFT?</div>
            </div>
            <div className="text-[#C0C0C0] md:text-[18px] text-[16px] font-montserrat600 max-w-[600px] leading-[32px] md:mt-[15px] mt-5">
              <span className="text-white">Defylo’s Membership NFT</span> is a
              tier-based digital membership that boosts your earning potential
              and unlocks exclusive benefits within the platform.
              <br /> Depending on the tier you hold, you can access up to{" "}
              <span className="text-white">
                +200% yield enhancements, priority access, exclusive rewards
              </span>
              , and more.
            </div>
          </div>
          <img src={ImgNft} alt="nft" />
        </div>
      </div>
    </div>
  );
}

export default WhatIsMembershipNft;
