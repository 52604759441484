import ImgBanner from "@/assets/images/membership/nft/banner_nft.svg";
import ImgDots from "@/assets/images/membership/nft/dots.svg";
import ImgHead from "@/assets/images/membership/nft/head.svg";
import ImgSub from "@/assets/images/membership/nft/sub.svg";
import ImgBannerMobile from "@/assets/images/membership/nft/sub_mobile.svg";
import CheckRank from "@/components/CheckRank";

interface Props {
  dataProduct: any;
}

function NftIssuance({ dataProduct }: Props) {
  return (
    <div className="max-w-[1312px] px-4 mx-auto md:pt-20 pt-[65px] pb-[73px] w-full">
      <div className="flex gap-[166px]">
        <div className="lg:pt-[44px]">
          <div className="md:text-[38px] text-[32px] font-montserrat600 mb-4">
            NFT Issuance & Distribution Schedule
          </div>
          <div className="flex flex-col gap-3">
            <div className="flex lg:items-center items-start gap-[8.5px]">
              <img src={ImgDots} alt="img" className="lg:mt-0 mt-[6px]" />
              <div className="text-[#C0C0C0] text-[18px]">
                <span className="font-montserrat700">
                  Official NFT launch date:
                </span>
                To be announced (currently under internal coordination)
              </div>
            </div>
            <div className="flex lg:items-center items-start gap-[8.5px]">
              <img src={ImgDots} alt="img" className="lg:mt-0 mt-[6px]" />
              <div className="text-[#C0C0C0] text-[18px]">
                <span className="font-montserrat700">
                  Early Access (Phase 1):{" "}
                </span>
                Limited to <span className="text-[#B0F127]">10%</span> of total
                supply for each tier — early purchase available on a first-come,
                first-served basis
              </div>
            </div>
          </div>
          <div className="md:mt-[64px] mt-10">
            <div className="flex items-center gap-3 mb-4">
              <img src={ImgHead} alt="img" />
              <div className="md:text-[28px] text-[25px] font-montserrat600">
                Total Supply by Tier
              </div>
            </div>

            <div className="flex gap-y-3 flex-wrap">
              {dataProduct?.map((dt: any) => (
                <div className="sm:w-1/2 w-full" key={dt?.id}>
                  <div className="flex items-center gap-[9.5px] flex-1">
                    <CheckRank rank={dt?.level} />
                    <div className="text-[#C0C0C0] text-[18px]">
                      <span className="font-montserrat700">
                        {dt?.productName}:{" "}
                      </span>
                      {dt?.totalSupply} NFTs
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>

        <img src={ImgSub} alt="img" className="lg:block hidden" />
      </div>

      <div className="flex items-center gap-3 mb-4 md:mt-16 mt-10">
        <img src={ImgHead} alt="img" />
        <div className="md:text-[28px] text-[25px] font-montserrat600">
          Distribution Method
        </div>
      </div>
      <div className="text-[#C0C0C0] text-[18px]">
        NFTs will be airdropped to pre-registered wallet addresses upon final
        issuance confirmation.
      </div>

      <div className="md:my-[68px] my-12">
        <img src={ImgBanner} alt="img" className="sm:block hidden" />
        <img
          src={ImgBannerMobile}
          alt="img"
          className="block sm:hidden w-full"
        />
      </div>

      <div className="md:text-[38px] text-[32px] font-montserrat600 mb-4">
        How to Participate
      </div>
      <div className="text-[#C0C0C0] text-[18px]">
        NFT registration and application can be submitted via the official page.{" "}
        <br /> Successful early applicants will be prioritized during allocation
        and distribution once the minting process is confirmed.
      </div>

      <div className="md:my-16 my-10">
        <div className="md:text-[38px] text-[32px] font-montserrat600 mb-4">
          Important Notices
        </div>
        <div className="flex flex-col gap-3">
          <div className="flex lg:items-center items-start gap-[8.5px]">
            <img src={ImgDots} alt="img" className="lg:mt-0 mt-[6px]" />
            <div className="text-[#C0C0C0] text-[18px]">
              NFTs will be issued in limited quantities only —{" "}
              <span className="font-montserrat700">
                no additional minting is planned.
              </span>
            </div>
          </div>
          <div className="flex lg:items-center items-start gap-[8.5px]">
            <img src={ImgDots} alt="img" className="lg:mt-0 mt-[6px]" />
            <div className="text-[#C0C0C0] text-[18px]">
              The issuance timeline may change depending on internal or market
              conditions.
            </div>
          </div>
          <div className="flex lg:items-center items-start gap-[8.5px]">
            <img src={ImgDots} alt="img" className="lg:mt-0 mt-[6px]" />
            <div className="text-[#C0C0C0] text-[18px]">
              Once submitted,{" "}
              <span className="font-montserrat700">
                wallet addresses cannot be changed
              </span>
              , so please double-check before registering.
            </div>
          </div>
          <div className="flex lg:items-center items-start gap-[8.5px]">
            <img src={ImgDots} alt="img" className="lg:mt-0 mt-[6px]" />
            <div className="text-[#C0C0C0] text-[18px]">
              A{" "}
              <span className="font-montserrat700">
                secondary NFT marketplace integration
              </span>{" "}
              within the platform is under consideration.
            </div>
          </div>
        </div>
      </div>

      <div className="md:text-[38px] text-[32px] font-montserrat600 mb-4">
        Future Value Potential
      </div>
      <div className="flex flex-col gap-3">
        <div className="flex lg:items-center items-start gap-[8.5px]">
          <img src={ImgDots} alt="img" className="lg:mt-0 mt-[6px]" />
          <div className="text-[#C0C0C0] text-[18px] font-montserrat700">
            Platform growth = Increased NFT value.
          </div>
        </div>
        <div className="flex lg:items-center items-start gap-[8.5px]">
          <img src={ImgDots} alt="img" className="lg:mt-0 mt-[6px]" />
          <div className="text-[#C0C0C0] text-[18px]">
            Exclusive earning programs and early access to new services for NFT
            holders.
          </div>
        </div>
        <div className="flex lg:items-center items-start gap-[8.5px]">
          <img src={ImgDots} alt="img" className="lg:mt-0 mt-[6px]" />
          <div className="text-[#C0C0C0] text-[18px]">
            Fixed supply may create{" "}
            <span className="font-montserrat700">
              premium value in secondary markets.
            </span>
          </div>
        </div>
        <div className="flex lg:items-center items-start gap-[8.5px]">
          <img src={ImgDots} alt="img" className="lg:mt-0 mt-[6px]" />
          <div className="text-[#C0C0C0] text-[18px]">
            On-chain member benefits will continue to expand beyond yield
            boosts.
          </div>
        </div>
      </div>
    </div>
  );
}

export default NftIssuance;
